import React from 'react';
import Layout from '../components/Layout';
import { graphql, useStaticQuery } from 'gatsby';
import SeoPage from '../components/SEO/SeoPage';
import ExpertCard from '../components/ExpertCard';
import styled from 'styled-components';
import tw from 'twin.macro';

const GridExperts = styled.div`
    ${tw`grid justify-center grid-flow-row-dense auto-rows-max gap-4 md:gap-8 px-4`}
    grid-template-columns: repeat(2, minmax(290px, 375px));
    @media (max-width:640px): {
      grid-template-columns: repeat(1, minmax(200px, 375px))
    };
`

export default function ExpertQuery() {
  const experts = useStaticQuery(
    graphql`
      query totalCount {
        allPrismicBlogPost(filter: {data: {verified: {eq: true}}}) {
          totalCount
        }
        allPrismicExpert {
          nodes {
            data {
              certifications
              description_short {
                text
              }
              name {
                text
              }
              linkedin {
                url
              }
            }
          }
          totalCount
        }
      }
      
    `
  );


  const totalVerifiedArticles = experts.allPrismicBlogPost.totalCount;
 console.log(experts)
  return (
    <Layout>
      <SeoPage
        title="Nuestros Expertos"
        description="This page list all the experts who review articles on this website."
      />
      <main className="text-center mt-5">
        <h1>Nuestros Expertos</h1>
        <h4 className="my-8 px-2 max-w-xl mx-auto font-normal">
        Para garantizar que nuestros artículos sean precisos y estén actualizados, pedimos a médicos, enfermeras, dietistas registrados, entrenadores personales certificados y otros expertos acreditados con experiencia que revisen los artículos relacionados con su experiencia. Al revisar estos artículos, estos expertos confirman que la información contenida es exhaustiva y que presentan lo último en investigación basada en evidencia.
        </h4>
        <div className="grid grid-cols-2 divide-x divide-gray-500 my-4 justify-center">
          <p className="flex flex-col justify-self-end pr-8 text-2xl">
            {experts.allPrismicExpert.totalCount}
            <span className="text-base">Experts</span>
          </p>
          <p className="flex flex-col justify-self-start pl-8 text-2xl">
            {totalVerifiedArticles}
            <span className="text-base">Articles Reviewed</span>
          </p>
        </div>
      </main>
      <GridExperts>
        {experts.allPrismicExpert.nodes.map((expert, i) => (
          <ExpertCard expert={expert} key={i} />
        ))}
      </GridExperts>
    </Layout>
  );
}